.handContainer {
    border: 1px solid white;
    min-width: 30px;
    min-height: 50px;
    border-radius: 4px;
}

.handTitle {
    font-size: 24px;
    -webkit-text-stroke: 1px black;
    text-shadow: black 0 0 4px;
    position: absolute;
}

.proportionsContainer {
    display: inline;
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.proportionBackground {
    padding: 0;
    margin: 0;
    float: left;
    height: 100%;
    border-radius: 4px;
}