.editProportionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editProportionItemContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
}