.stackSizeContainer {
    display: flex;
    flex-direction: column;
}

.selectedStackSizeContainer {
    flex-grow: 1;
}

.handsContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
}